import { API } from '../shared/api-responses';
import { ClassRole, MeetingStatus } from '../shared/types';
import { ClassRecordings, MeetingRecording, MeetingType, OnlineMeeting, RecordConfig } from './types';

export function onlineMeetingFactory({
  courseId,
  classId,
  classRole,
  classInState,
  onlineDetails,
}: {
  courseId: MongoId;
  classId: MongoId;
  classRole: ClassRole;
  classInState: OnlineMeeting | undefined;
  onlineDetails: API.ClassOnlineDetails;
}): OnlineMeeting {
  return {
    type: MeetingType.ZOOM,

    classId,
    courseId,
    myClassRole: classRole,
    closeUrl: classInState?.closeUrl ?? '',

    authorizationURL: onlineDetails.authUrl || '',
    canAutoJoinMeeting: true,
    hasAuthorizedAcadly:
      onlineDetails.isUserAuthenticated === undefined
        ? classInState?.hasAuthorizedAcadly ?? false
        : Boolean(onlineDetails.isUserAuthenticated),
    isBroadcastedFromCurrentDevice: classInState?.isBroadcastedFromCurrentDevice ?? false,
    isBroadcasting: Boolean(onlineDetails.beingBroadcast),
    isReadyToBroadcast: onlineDetails.meetingInProgress === MeetingStatus.IN_PROGESS,
    joinId: onlineDetails.joinId,
    meetingId: onlineDetails.meetingId,
    participantsById: {},
    password: onlineDetails.meetingPassword,

    status: onlineDetails.meetingInProgress,

    hostUserId: onlineDetails.hostUserId,
    hostDetails: onlineDetails.hostDetails,
  };
}

export function recordConfigFactory(
  autoRecordDefaults: API.GetClassDetailsResponse['autoRecordDefaults']
): RecordConfig {
  return {
    isAllowed: Boolean(autoRecordDefaults?.canCloudRecord),
    autoRecord: Boolean(autoRecordDefaults?.autoCloudRecord),
  };
}

export function classRecordingFactory({
  classId,
  autoRecordDefaults,
  recordings,
  userData,
}: {
  classId: MongoId;
  autoRecordDefaults: API.GetClassDetailsResponse['autoRecordDefaults'];
  recordings: API.ClassRecordings | undefined;
  userData: API.ClassPageUserData;
}): ClassRecordings {
  const config = recordConfigFactory(autoRecordDefaults);

  if (!recordings) {
    return { byId: null, config, data: null };
  }

  return {
    byId: null,
    config,
    data: {
      hasViewed: Boolean(userData?.recordings) && userData?.recordings[classId] === 'viewed',
      isPublished: Boolean(recordings.published),
      recordingsAvailable: recordings.recordingsAvailable,
      status: recordings.status,
    },
  };
}

export function meetingRecordingFactory({
  recordingId,
  meetingName,
  recordingFiles,
  createdAt,
}: API.ClassRecordingsData): MeetingRecording {
  const filesByName: MeetingRecording['filesByName'] = {};

  for (const { displayName, name, type } of recordingFiles) {
    filesByName[name] = { displayName, name, type };
  }

  return {
    recordingId,
    meetingName,
    filesByName,
    createdAt,
  };
}
