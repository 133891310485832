import { useTranslation } from 'react-i18next';

import Alert from '../../components/Alert';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import useRemoteAttendanceInProgressDialogVM from './vm';

const RemoteAttendanceInProgressDialog = () => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING]);

  const { buttonText, isOpen, disablePortal, markAttendance, message } =
    useRemoteAttendanceInProgressDialogVM();

  return (
    <Alert open={isOpen} disablePortal={disablePortal}>
      <Alert.Header>{t('attendance_is_in_progress', { ns: i18nNS.ONLINE_MEETING })}</Alert.Header>
      <Alert.Body>
        <Typography variant="paragraphRegular">{message}</Typography>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action onClick={markAttendance}>{buttonText}</Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default RemoteAttendanceInProgressDialog;
