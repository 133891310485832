import { Box, Typography } from '@mui/material';

const TypographyPlayground = () => {
  return (
    <Box sx={{ margin: 1, padding: 2 }}>
      <Typography variant="displayBold">This is Display variant</Typography>
      <Typography variant="displayMedium">This is Display variant</Typography>
      <Typography variant="displayRegular">This is Display variant</Typography>

      <Typography variant="h1Bold">This is H1 variant</Typography>
      <Typography variant="h1Medium">This is H1 variant</Typography>
      <Typography variant="h1Regular">This is H1 variant</Typography>

      <Typography variant="h2Bold">This is H2 variant</Typography>
      <Typography variant="h2Medium">This is H2 variant</Typography>
      <Typography variant="h2Regular">This is H2 variant</Typography>

      <Typography variant="h3Bold">This is H3 variant</Typography>
      <Typography variant="h3Medium">This is H3 variant</Typography>
      <Typography variant="h3Regular">This is H3 variant</Typography>

      <Typography variant="h4Bold">This is H4 variant</Typography>
      <Typography variant="h4Medium">This is H4 variant</Typography>
      <Typography variant="h4Regular">This is H4 variant</Typography>

      <Typography variant="h5Bold">This is H5 variant</Typography>
      <Typography variant="h5Medium">This is H5 variant</Typography>
      <Typography variant="h5Regular">This is H5 variant</Typography>

      <Typography variant="h6Bold">This is H6 variant</Typography>
      <Typography variant="h6Medium">This is H6 variant</Typography>
      <Typography variant="h6Regular">This is H6 variant</Typography>

      <Typography variant="paragraphBold">This is paragraph variant</Typography>
      <Typography variant="paragraphMedium">This is paragraph variant</Typography>
      <Typography variant="paragraphRegular">This is paragraph variant</Typography>
      <Typography variant="link">This is Link variant</Typography>

      <Typography variant="titleBold">This is title variant</Typography>
      <Typography variant="titleMedium">This is title variant</Typography>
      <Typography variant="titleRegular">This is title variant</Typography>

      <Typography variant="textSmBold">This is textSm variant</Typography>
      <Typography variant="textSmMedium">This is textSm variant</Typography>
      <Typography variant="textSmRegular">This is textSm variant</Typography>

      <Typography variant="textXsBold">This is textXs variant</Typography>
      <Typography variant="textXsMedium">This is textXs variant</Typography>
      <Typography variant="textXsRegular">This is textXs variant</Typography>
    </Box>
  );
};

export default TypographyPlayground;
