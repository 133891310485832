import { Size } from '../types';

export const DEFAULT_MIN_HEIGHT = 275;
export const DEFAULT_MIN_WIDTH = 360;

interface PipSize extends Size {}

export function getInitialPipModeSize({
  aspectRatio,
  lockAspectRatioExtraHeight = 0,
  lockAspectRatioExtraWidth = 0,
  minHeight = DEFAULT_MIN_HEIGHT,
  minWidth = DEFAULT_MIN_WIDTH,
}: {
  aspectRatio?: number;
  lockAspectRatioExtraHeight?: number;
  lockAspectRatioExtraWidth?: number;
  minHeight?: number;
  minWidth?: number;
}): PipSize {
  if (!aspectRatio) {
    return {
      width: minWidth + lockAspectRatioExtraWidth,
      height: minHeight + lockAspectRatioExtraHeight,
    };
  }

  const w1 = aspectRatio * (minHeight - lockAspectRatioExtraHeight) + lockAspectRatioExtraWidth;
  const h1 = minHeight;

  const w2 = minWidth;
  const h2 = (minWidth - lockAspectRatioExtraWidth) / aspectRatio + lockAspectRatioExtraHeight;

  if (w1 >= minWidth && h1 >= minHeight && w2 >= minWidth && h2 >= minHeight) {
    // if both satisfies the constraints then pick smaller area
    const area1 = w1 * h1;
    const area2 = w2 * h2;
    return {
      width: area1 > area2 ? w2 : w1,
      height: area1 > area2 ? h2 : h1,
    };
  }

  if (w1 >= minWidth && h1 >= minHeight) {
    return { width: w1, height: h1 };
  }

  return { width: w2, height: h2 };
}

export function computeEmbedTargetSize({
  target,
  aspectRatio,
  lockAspectRatioExtraWidth = 0,
  lockAspectRatioExtraHeight = 0,
}: {
  target?: Element;
  aspectRatio?: number;
  lockAspectRatioExtraWidth?: number;
  lockAspectRatioExtraHeight?: number;
}) {
  if (!target) return { width: 0, height: 0 };
  const embedTargetBounds = target.getBoundingClientRect();

  const contentWidth = embedTargetBounds.width - lockAspectRatioExtraWidth;
  const contentHeight = aspectRatio
    ? contentWidth / aspectRatio
    : embedTargetBounds.height - lockAspectRatioExtraHeight;

  const width = contentWidth + lockAspectRatioExtraWidth;
  const height = contentHeight + lockAspectRatioExtraHeight;

  return { width, height };
}
