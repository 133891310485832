const en = {
  /** Hamburger menu */
  click_to_open_menu: 'click to open menu',
  trial_account: 'Trial account',
  click_to_navigate_to_settings_page: 'click to go to settings page',
  click_to_send_a_message_to_the_acadly_team: 'click to send a message to the acadly team',
  click_to_logout: 'click to logout',
  powered_by: 'Powered by',
  version_num: 'Version {{num}}',

  /** discussion panel */
  click_to_collapse_comments_panel: 'click to collapse the comments panel',
  click_to_expand_comments_panel: 'click to expand the comments panel',
  click_to_hide_discussion_panel: 'click to hide the discussion panel',
  click_to_subscribe_to_this_discussion_thread: 'click to subscribe to this discussion thread',
  click_to_unsubscribe_from_this_discussion_thread: 'click to unsubscribe from this discussion thread',
  click_to_export_students_posts: 'click to export comments stats',
  subscribed_we_will_send_a_notification_to_you_whenever_someone_posts_a_comment_in_this_thread:
    'Subscribed: We will send a notification to you whenever someone posts a comment to this thread',
  unsubscribed_you_will_now_not_receive_a_notification_whenever_someone_posts_a_comment_in_this_thread:
    'Unsubscribed: You will now NOT receive a notification whenever someone posts a comment to this thread',

  warning_this_timing_seems_a_bit_inconvinient: 'Warning: This timing seems a bit inconvenient',
  start_time_time_click_to_change_start_time: 'start time {{time}}, click to change the start time',
  end_time_time_click_to_change_end_time: 'end time {{time}}, click to change the end time',
  type_venue_link_here: 'Type venue or link here',
  select_classroom_modality: 'Select classroom modality',
  online_on_acadly_with_zoom_plugin: 'Online with Acadly (with Zoom plugin)',
  click_to_select_online_classroom_on_acadly_with_zoom_plugin:
    'click to select take the lecture online on acadly with zoom plugin',
  in_person_or_on_another_online_platform: 'In-person (or on another online platform)',
  click_to_select_in_person_classroom_or_classroom_on_another_online_platform:
    'click to select in person classroom or classroom on another online platform',

  do_you_want_to_discard_changes_made_to_activity: 'Do you want to discard the changes made to {{activity}}?',
  click_to_cancel_discarding_changes: 'click to not discard the changes',
  click_to_update_activity: 'click to update {{activity}}',
  click_to_cancel_updating_activity: 'click to cancel updating {{activity}}',
  are_you_sure_you_want_to_delete_this_activity: 'Are you sure you want to delete this {{activity}}?',
  click_to_delete_activity: 'click to delete {{activity}}',
  click_to_cancel_deleting_activity: 'click to cancel deleting {{activity}}',
  click_to_copy_activity_to_another_class: 'click to copy {{activity}} to another class',
  click_to_move_activity_to_another_class: 'click to move {{activity}} to another class',
  click_to_publish_activity: 'click to publish {{activity}}',
  click_to_edit_activity: 'click to edit {{activity}}',
  click_to_submit_activity: 'click to submit {{activity}}',

  to_be_done_activity_for_the_class_on_date_at_time:
    '{{toBeDone}} {{activity}} for the class on {{date}} at {{time}}.',
  you_must_publish_this_activity_by_date_at_time_for_students_to_attempt_it_before_the_class:
    'You must publish this activity by {{date}} at {{time}} for students to attempt it before the class.',
  the_activity_doesn_t_have_a_title_yet_click_on_the_edit_icon_to_add_a_title:
    "The {{activity}} doesn't have a title yet. Click on the <1></1> icon to add a title",
  click_to_edit_activity_title: 'click to edit {{activity}} title',
  activity_title: '{{activity}} title',
  add_activity_title: 'Add {{activity}} {{title}}',
  click_to_cancel_editing_activity_title: 'click to cancel editing {{activity}} title',
  click_to_update_activity_title: 'click to update {{activity}} title',
  this_activity_doesnt_have_any_field_title_yet: "This {{activity}} doesn't have any {{fieldTitle}} yet.",
  this_activity_doesn_t_have_any_field_title_yet_click_on_the_edit_icon_to_add_field_title:
    "This {{activity}} doesn't have any {{fieldTitle}} yet. Click on the <3></3> icon to add {{fieldTitle}}",
  click_to_save_activity_field_title: 'click to save {{activity}} {{fieldTitle}}',
  click_to_discard_activity_field_title_changes: 'click to discard {{activity}} {{fieldTitle}} changes',
  created_by_name: 'Created by {{name}}',
  published_by_name: 'Published by {{name}}',
  date_at_time: '{{date}} at {{time}}',
  the_activity_must_be_published_by_the_class_in_charge_for_the_students_to_be_able_to_access_it:
    'The activity must be published by the class in-charge for the students to be able to access it.',
  only_the_class_in_charge_can_edit_this_activity: 'Only the class in-charge can edit this activity.',
  you_cannot_edit_activity_questions_after_a_few_submissions_have_been_made:
    'You cannot edit {{activity}} questions after a submission has been made.',

  /** Copy activity drawer */
  copy_to_course: 'Copy to course',
  copy_to_class: 'Copy to class',
  there_are_no_available_classes_to_copy_this_activity_into:
    'There are no available classes to copy this activity into',
  copy_as: 'Copy as',
  click_to_select_course: 'click to select course',
  click_to_select_class: 'click to select class',

  /** Move activity drawer */
  move_activity: 'Move activity',
  there_are_no_available_classes_to_move_this_activity_into:
    'There are no available classes to move this activity into',
  move_activity_as: 'Move activity as',
  no_questions_added_yet: 'No questions added yet.',

  /** To be done dialog */
  click_to_select_pre_class: 'click to select pre-class',
  click_to_select_in_class: 'click to select in-class',
  click_to_action_this_activity_as_to_be_done: 'click to {{action}} this activity as {{toBeDone}}',

  to_be_done_activity_for_the_class_on_date_in_course_code_colon_course_title:
    '{{toBeDone}} {{activity}} for the class on {{date}} in {{courseCode}}: {{courseTitle}}',
  don_t_suggest: "Don't suggest",
  activity_will_be_hidden: 'Activity will be hidden',
  this_activity_will_no_longer_be_suggested_in_the_future_you_can_still_select_this_activity_by_accessing_the_list_of_all_activities_from_the_source_course:
    'This activity will no longer be suggested in the future. You can still select this activity by accessing the list of all activities from the source course.',
  click_to_hide_activity: 'click to hide activity',
  click_to_cancel_hiding_activity: 'click to cancel hiding activity',
  click_to_confirm_hide_activity: 'click to confirm hide activity',
  use_activity_as: 'Use activity as',

  /**  aria-label */
  click_to_delete_this_activity: 'click to delete this {{activity}}?',
  click_to_make_payment: 'click to make payment',
  click_to_use_suggested_activity_name: 'click to use suggested activity {{activityName}}',

  click_to_close_expanded_menu: 'click to close expanded menu',
};

export default en;
