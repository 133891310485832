import { ChildFabMenu, FabMenu, FabMenuItem } from '../components/FabMenu';
import ArrowBackIcon from '../icons/ArrowBackIcon';
import BarChartIcon from '../icons/BarChartIcon';
import LayersIcon from '../icons/LayersIcon';
import MessageSquareIcon from '../icons/MessageSquareIcon';
import QuizIcon from '../icons/QuizIcon';

const FabMenuPlayground = () => {
  return (
    <div>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur, sapiente mollitia error repellat
      repellendus eligendi, ipsam tenetur asperiores cum voluptatem libero cupiditate temporibus aut hic
      exercitationem facilis aliquam itaque quis.
      <FabMenu rootMenu="add_activity_menu">
        <ChildFabMenu menuId="add_activity_menu">
          <FabMenuItem targetMenu="pre_class_menu">Pre class</FabMenuItem>
          <FabMenuItem targetMenu="in_class_menu">In class</FabMenuItem>
        </ChildFabMenu>
        <ChildFabMenu menuId="pre_class_menu">
          <FabMenuItem disabled>Add Pre-class Activity</FabMenuItem>
          <FabMenuItem icon={<QuizIcon />}>Quiz</FabMenuItem>
          <FabMenuItem icon={<BarChartIcon />}>Poll</FabMenuItem>
          <FabMenuItem icon={<MessageSquareIcon />}>Discussion</FabMenuItem>
          <FabMenuItem icon={<LayersIcon />}>Resource</FabMenuItem>
          <FabMenuItem targetMenu="add_activity_menu" icon={<ArrowBackIcon />}>
            Back
          </FabMenuItem>
        </ChildFabMenu>
        <ChildFabMenu menuId="in_class_menu">
          <FabMenuItem disabled>Add In-class Activity</FabMenuItem>
          <FabMenuItem icon={<QuizIcon />}>Quiz</FabMenuItem>
          <FabMenuItem icon={<BarChartIcon />}>Poll</FabMenuItem>
          <FabMenuItem icon={<MessageSquareIcon />}>Discussion</FabMenuItem>
          <FabMenuItem icon={<LayersIcon />}>Resource</FabMenuItem>
          <FabMenuItem targetMenu="add_activity_menu" icon={<ArrowBackIcon />}>
            Back
          </FabMenuItem>
        </ChildFabMenu>
      </FabMenu>
    </div>
  );
};
export default FabMenuPlayground;
