import { forwardRef, Ref } from 'react';

import clsx from 'clsx';

import { omit } from '../../../utils/helpers';
import TextFieldBase from '../TextFieldBase';
import { Listbox, PopupIcon, Props, StyledAutocomplete } from './styles';
import useDropdownVM from './vm';

export type { Props };

const Dropdown = <
  OptionType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  {
    label,
    size = 'medium',
    required = false,
    error = false,
    helperIcon,
    helperText,
    disabled,
    className,
    classes,
    ...props
  }: Props<OptionType, Multiple, DisableClearable, FreeSolo>,
  ref: Ref<HTMLDivElement>
) => {
  const { ids } = useDropdownVM(props);

  return (
    <StyledAutocomplete
      {...props}
      ref={ref}
      id={ids.dropdown}
      className={clsx(className, classes?.root)}
      renderInput={({ ...params }) => {
        const { InputLabelProps, InputProps, ...restParams } = params;
        const { ref, startAdornment, endAdornment, className } = InputProps;
        return (
          <TextFieldBase
            {...restParams}
            label={label}
            required={required}
            /** Props applied to Input tag */
            InputBaseRef={ref}
            classes={{
              ...(classes ? omit(classes, 'root', 'textFieldRoot', 'popupIcon') : {}),
              root: classes?.textFieldRoot,
              inputBase: clsx(className, classes?.inputBase),
            }}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            size={size}
            error={error}
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            disabled={disabled}
            /** Props applied to HelperText */
            helperIcon={helperIcon}
            helperText={helperText}
          />
        );
      }}
      popupIcon={<PopupIcon className={classes?.popupIcon} />}
      ListboxComponent={Listbox}
      disabled={disabled}
    />
  );
};

export default forwardRef(Dropdown);
