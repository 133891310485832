import { useCallback, useEffect } from 'react';

import { PipContainerMode } from '../../pip-container';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export default function useZoomAppHeaderVM() {
  const { container, pipRef, pipMode, isFullScreen, setIsFullScreen } = useZoomMeetingContext();

  const requestFullScreen = () => {
    return pipRef.current?.requestFullScreen();
  };

  const exitFullscreen = () => {
    pipRef.current?.exitFullscreen();
  };

  const handleMinimise = async () => {
    pipRef.current?.resetPosition();
    pipRef.current?.resetSize();
  };

  const handlePopOut = () => {
    return pipRef.current?.requestModeChange(PipContainerMode.PIP);
  };

  const requestEmbeddedMode = async () => {
    return pipRef.current?.requestModeChange(PipContainerMode.EMBEDDED);
  };

  const handleEscKeyDown = useCallback(() => {
    setIsFullScreen((prevVal) => !prevVal);
  }, [setIsFullScreen]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleEscKeyDown);
    return () => {
      document.removeEventListener('fullscreenchange', handleEscKeyDown);
    };
  }, [handleEscKeyDown]);

  return {
    container,
    exitFullscreen,
    handleMinimise,
    handlePopOut,
    pipMode,
    requestEmbeddedMode,
    isFullScreen,
    requestFullScreen,
  };
}
