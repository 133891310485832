import { useMemo } from 'react';

import { randomStr } from '../../../utils/helpers';

export interface Props {
  id?: string;
}

const useDropdownVM = ({ id }: Props) => {
  const ids = useMemo(
    () => ({
      dropdown: id || `dropdown-${randomStr(4)}`,
    }),
    [id]
  );

  return { ids };
};

export default useDropdownVM;
