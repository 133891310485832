import { useState } from 'react';

import { useInterval } from '../../utils/timer';

const Interval = ({ delay }: { delay: number | null }) => {
  const [count, setCount] = useState(0);

  const { start, stop } = useInterval(() => {
    setCount((count) => count + 1);
  }, delay);

  return (
    <>
      <div>Count: {count}</div>
      <div>Delay: {delay}</div>
      <button onClick={() => start(1000)}>Start</button>
      <button onClick={stop}>Stop</button>
    </>
  );
};

const IntervalPlayground = () => {
  const [delay, setDelay] = useState<number | null>(1000);
  const [isMounted, setIsMounted] = useState(true);

  return (
    <div>
      {isMounted && <Interval delay={delay} />}
      <button onClick={() => setDelay((delay) => (delay === 1000 ? 2000 : 1000))}>Change delay</button>
      <button onClick={() => setIsMounted((isMounted) => !isMounted)}>
        {isMounted ? 'Unmount' : 'Mount'}
      </button>
    </div>
  );
};

export default IntervalPlayground;
