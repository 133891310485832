import UserCell from '../components/UserCell/index';
import UserList from '../components/UserList';

const users = [
  {
    courseStatus: 'active',
    avatar: '5e4e190c03570666e1b931ea',
    emailId: 'vishnu.singh+s2@acetone.co',
    name: 'Arun K.',
    role: 'student',
    userId: '5e4e190c03570666e1b931f1',
    joinedOn: 1589197154,
    lastActive: 1630566856,
    lastRemovedOn: 0,
  },
  {
    courseStatus: 'active',
    avatar: '5e4e081103570666e1b93175',
    emailId: 'ravinder.kumar+s2@acetone.co',
    name: 'Ravinder Kumar S2',
    role: 'student',
    userId: '5e4e081103570666e1b9317a',
    joinedOn: 1597655117,
    lastActive: 1600943315,
    lastRemovedOn: 0,
  },
  {
    courseStatus: 'active',
    avatar: '5e4e081103570666e1b93173',
    emailId: 'ravinder.kumar+s1@acetone.co',
    name: 'Ravinder S1',
    role: 'student',
    userId: '5e4e081103570666e1b93179',
    joinedOn: 1597143062,
    lastActive: 1640694284,
    lastRemovedOn: 0,
  },
  {
    courseStatus: 'active',
    avatar: '5e4e190c03570666e1b931ec',
    emailId: 'vishnu.singh+s3@acetone.co',
    name: 'Surbhi M',
    role: 'student',
    userId: '5e4e190c03570666e1b931f2',
    joinedOn: 1589198230,
    lastActive: 1612275543,
  },
  {
    courseStatus: 'active',
    avatar: '5e4e190c03570666e1b931e8',
    emailId: 'vishnu.singh+s1@acetone.co',
    name: 'Umang S.',
    role: 'student',
    userId: '5e4e190c03570666e1b931f0',
    joinedOn: 1589197154,
    lastActive: 1642415388,
    lastRemovedOn: 0,
  },
  {
    courseStatus: 'active',
    avatar: '5f462f35a38c5b0a52d535d9',
    emailId: 'vishnu.singh+s5@acetone.co',
    name: 'Vishnu S5',
    role: 'student',
    userId: '5f462f35a38c5b0a52d535db',
    joinedOn: 1598436199,
    lastActive: 1642655793,
  },
];

const UserListPlayground = () => {
  return (
    <UserList users={users} showFilterAndSort>
      {(user) => (
        <UserCell
          key={user.userId}
          avatar={user.avatar}
          title={user.name}
          subtitle={`Last active: ${user.lastActive}`}
        />
      )}
    </UserList>
  );
};

export default UserListPlayground;
