import { ToastOptions } from 'react-toastify';

import { Button } from '@mui/material';

import { notify } from '../app/NotificationProvider';

const NotificationPlayground = () => {
  const handleShowNotification = (type: ToastOptions['type']) => {
    notify({
      id: '5e4e02ea03570666e1b93157',
      avatar: Math.random() > 0.5 ? '5e4e02e903570666e1b93155' : undefined,
      content:
        'Quiz 12 published as a Pre-class activity for the lecture scheduled to begin at 10:00 AM on Sat, Nov 5th',
      options: { onClick: () => console.log('test'), type },
      playSound: true,
    });
  };

  return (
    <>
      <Button onClick={() => handleShowNotification('default')}>Show Default Notification</Button>
      <Button onClick={() => handleShowNotification('success')}>Show Success Notification</Button>
      <Button onClick={() => handleShowNotification('error')}>Show Error Notification</Button>
      <Button onClick={() => handleShowNotification('warning')}>Show Warning Notification</Button>
      <Button onClick={() => handleShowNotification('info')}>Show Info Notification</Button>
    </>
  );
};

export default NotificationPlayground;
