import { styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';

export const pipContainerClasses = generateClasses('PipContainer', [
  'root',
  'resizeAndDragRoot',
  'body',
  'modePip',
  'modeEmbedded',
]);

export const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  pointerEvents: 'none',

  [`&.${pipContainerClasses.modePip}`]: {
    inset: 0,
    zIndex: theme.zIndex.pip,
    pointerEvents: 'none',
    [`& .${pipContainerClasses.resizeAndDragRoot}`]: {
      padding: theme.spacing(0.5),
      zIndex: theme.zIndex.pip,
    },
  },

  [`& .${pipContainerClasses.resizeAndDragRoot}`]: {
    pointerEvents: 'all',
  },
}));

export const PipBody = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  height: '100%',

  [`.${pipContainerClasses.modePip} &`]: {
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
  },
}));
