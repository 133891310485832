import { Stack, ThemeProvider } from '@mui/material';

import darkTheme from '../../styles/theme/dark';
import lightTheme from '../../styles/theme/light';
import Tooltips from './Tooltips';

const TooltipPlayground = () => {
  return (
    <Stack direction="row" minHeight="100vh">
      <ThemeProvider theme={lightTheme}>
        <Tooltips />
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <Tooltips />
      </ThemeProvider>
    </Stack>
  );
};

export default TooltipPlayground;
