import { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { RndDragCallback, RndResizeCallback } from 'react-rnd';

import { useEventListener } from '../../utils/hooks';
import { PipContainerInstance, PipContainerMode, Position, Size } from '../types';
import {
  computeEmbedTargetSize,
  DEFAULT_MIN_HEIGHT,
  DEFAULT_MIN_WIDTH,
  getInitialPipModeSize,
} from './helpers';

export interface UsePipContainer {
  id: string;
  /**
   * CSS selector of the html element that will be used to
   * be followed and covered by this pip-container in
   * `embedded-mode`, if the target is not found then fallback
   * to `pip-mode`.
   */
  embedTargetSelector: string;
  onModeChange?: (mode: PipContainerMode) => void;
  /**
   * Maximum allowed height
   * @default window.innerHeight
   */
  maxHeight?: number;
  /**
   * Maximum allowed width
   * @default window.innerWidth
   */
  maxWidth?: number;
  /**
   * Minimum allowed height
   * @default 275
   */
  minHeight?: number;
  /**
   * Minimum allowed width
   * @default 360
   */
  minWidth?: number;
  /**
   * If provided then resizing will done with maintaining the
   * aspect-ratio, otherwise the resize can be done freely.
   *
   * NOTE: this ratio does not include header and footer heights.
   */
  aspectRatio?: number;
  /**
   * disable resizing
   * @default false
   */
  disableResize?: boolean;
  /**
   * This height is not added in aspect-ratio calculation
   * of pip-container
   * @default 0
   */
  headerHeight?: number;
  /**
   * This height is not added in aspect-ratio calculation
   * of pip-container
   * @default 0
   */
  footerHeight?: number;
}

const usePipContainer = (
  {
    id,
    embedTargetSelector,
    onModeChange,
    maxHeight = window.innerHeight,
    maxWidth = window.innerWidth,
    minHeight = DEFAULT_MIN_HEIGHT,
    minWidth = DEFAULT_MIN_WIDTH,
    aspectRatio,
    disableResize = false,
    headerHeight = 0,
    footerHeight = 0,
  }: UsePipContainer,
  ref: Ref<PipContainerInstance>
) => {
  const rootElRef = useRef<HTMLDivElement>(null);
  const bodyElRef = useRef<HTMLDivElement>(null);

  // when user switches between different pages, we should remember last selected mode by user
  const userSelectedModeRef = useRef(PipContainerMode.EMBEDDED);
  const [mode, setMode] = useState(PipContainerMode.PIP);

  const lockAspectRatio = useMemo(() => {
    if (mode !== PipContainerMode.PIP) return false;
    if (aspectRatio === undefined) return false;
    return aspectRatio;
  }, [aspectRatio, mode]);

  const lockAspectRatioExtraHeight = useMemo(() => headerHeight + footerHeight, [footerHeight, headerHeight]);

  const lockAspectRatioExtraWidth = 0;

  // pip and embedded mode's positions are saved separately to resume position on mode change
  const positionRef = useRef({
    pip: { x: -1000, y: -1000 },
    embedded: { x: 0, y: 0 },
  });

  // creating state to let Rnd component know that position has changed
  const [position, setPosition] = useState<Position>(positionRef.current[mode]);

  // helper to set position in ref and state
  const updatePosition = useCallback(({ mode, position }: { mode: PipContainerMode; position: Position }) => {
    positionRef.current[mode] = position;
    setPosition(position);
  }, []);

  // pip and embedded mode's sizes are saved separately to resume size on mode change
  const sizeRef = useRef({
    pip: getInitialPipModeSize({
      aspectRatio,
      lockAspectRatioExtraHeight,
      lockAspectRatioExtraWidth,
      minHeight,
      minWidth,
    }),
    embedded: { width: minWidth, height: minHeight },
  });

  // creating state to let Rnd component know that position has changed
  const [size, setSize] = useState<Size>(sizeRef.current[mode]);

  // helper to set size in ref and state
  const updateSize = useCallback(({ mode, size }: { mode: PipContainerMode; size: Size }) => {
    sizeRef.current[mode] = size;
    setSize(size);
  }, []);

  /** Embedded Mode */

  const setEmbeddedPipPositionAndSize = useCallback(
    (embedTarget: Element) => {
      const embedTargetBounds = embedTarget.getBoundingClientRect();

      const updatedPosition: Position = {
        x: embedTargetBounds.x,
        y: embedTargetBounds.y,
      };

      updatePosition({ mode: PipContainerMode.EMBEDDED, position: updatedPosition });

      const { width, height } = computeEmbedTargetSize({
        target: embedTarget,
        aspectRatio,
        lockAspectRatioExtraWidth,
        lockAspectRatioExtraHeight,
      });

      const updatedSize: Size = {
        width,
        height,
      };

      updateSize({ mode: PipContainerMode.EMBEDDED, size: updatedSize });
    },
    [aspectRatio, lockAspectRatioExtraHeight, updatePosition, updateSize]
  );

  const computeEmbeddedPipPositionAndSize = useCallback(() => {
    if (mode !== PipContainerMode.EMBEDDED) return;

    const embedTarget = document.querySelector(embedTargetSelector);
    if (!embedTarget) return;

    setEmbeddedPipPositionAndSize(embedTarget);
  }, [embedTargetSelector, mode, setEmbeddedPipPositionAndSize]);

  // follow embed target on scroll
  useEventListener('scroll', computeEmbeddedPipPositionAndSize, undefined, { capture: true });
  // follow embed target on window resize
  useEventListener('resize', computeEmbeddedPipPositionAndSize);

  // set position and size on mode change
  useEffect(
    function onModeChangeToEmbedded() {
      computeEmbeddedPipPositionAndSize();
    },
    [computeEmbeddedPipPositionAndSize]
  );

  // change embed target height on mode change
  useEffect(
    function onModeChangeToEmbedded() {
      if (!bodyElRef.current) return;

      const embedTarget = document.querySelector(embedTargetSelector);
      if (!embedTarget) return;

      if (mode !== PipContainerMode.EMBEDDED) return;

      const { height } = computeEmbedTargetSize({
        target: embedTarget,
        aspectRatio,
        lockAspectRatioExtraWidth,
        lockAspectRatioExtraHeight,
      });

      (embedTarget as HTMLElement).style.height = `${height}px`;
    },
    [aspectRatio, embedTargetSelector, lockAspectRatioExtraHeight, mode]
  );

  // finds embed target on dom change and set appropriate mode with position and size
  useEffect(
    function watchEmbedTarget() {
      const embedTargetObserver = new MutationObserver(() => {
        const embedTarget = document.querySelector(embedTargetSelector);
        if (!embedTarget) {
          // fallback to pip mode
          setMode(PipContainerMode.PIP);
          return;
        }

        if (userSelectedModeRef.current !== PipContainerMode.EMBEDDED) return;

        setEmbeddedPipPositionAndSize(embedTarget);

        setMode(PipContainerMode.EMBEDDED);
      });

      embedTargetObserver.observe(document.body, { childList: true, subtree: true });

      return function cleanup() {
        embedTargetObserver.disconnect();
      };
    },
    [embedTargetSelector, mode, setEmbeddedPipPositionAndSize]
  );

  /** Pip Mode */

  // reset pip container position on bottom-left corner
  const resetPipModePosition = useCallback(() => {
    if (mode !== PipContainerMode.PIP) return;
    if (!rootElRef.current) return;

    const rootHeight = rootElRef.current.clientHeight;

    const updatedPosition: typeof positionRef.current.pip = {
      x: 0,
      y: rootHeight - sizeRef.current.pip.height,
    };

    updatePosition({ mode: PipContainerMode.PIP, position: updatedPosition });
  }, [mode, updatePosition]);

  const resetPipModeSize = useCallback(() => {
    if (mode !== PipContainerMode.PIP) return;
    const size = getInitialPipModeSize({
      aspectRatio,
      lockAspectRatioExtraHeight,
      lockAspectRatioExtraWidth,
      minHeight,
      minWidth,
    });
    updateSize({ mode: PipContainerMode.PIP, size });
  }, [aspectRatio, lockAspectRatioExtraHeight, minHeight, minWidth, mode, updateSize]);

  const onChangeToPipMode = useCallback(() => {
    resetPipModePosition();
    updateSize({ mode: PipContainerMode.PIP, size: sizeRef.current.pip });
  }, [resetPipModePosition, updateSize]);

  // set pip position on mode change
  useEffect(
    function onModeChangeToPip() {
      onChangeToPipMode();
    },
    [onChangeToPipMode]
  );

  const requestModeChange = useCallback(
    (nextMode: PipContainerMode) => {
      setMode(nextMode);
      onModeChange?.(nextMode);
      userSelectedModeRef.current = nextMode;
    },
    [onModeChange]
  );

  /** Full Screen */

  const [isFullscreen, setIsFullscreen] = useState(false);

  const requestFullScreen = useCallback(() => {
    setIsFullscreen(true);
    bodyElRef.current?.requestFullscreen();
  }, []);

  const exitFullscreen = useCallback(() => {
    document.exitFullscreen();
    setIsFullscreen(false);
  }, []);

  /** Drag and Resize */

  const disableDragging = useMemo(() => {
    /**
     * disabling dragging because when we exit full screen mode,
     * Rnd automatically calls onDragStop event, which is not necessary
     * and which disturbs our pip container position y in PIP mode.
     * Rnd version at the time of writing this comment: "react-rnd": "^10.3.7"
     */
    if (isFullscreen) return true;
    if (mode === PipContainerMode.PIP) return false;
    return true;
  }, [isFullscreen, mode]);

  const handleDragStop: RndDragCallback = useCallback(
    (e, data) => {
      const updatedPosition: Position = { x: data.x, y: data.y };
      updatePosition({ mode: PipContainerMode.PIP, position: updatedPosition });
    },
    [updatePosition]
  );

  const disableResizing = useMemo(() => {
    if (isFullscreen) return true;
    if (disableResize) return true;
    if (mode !== PipContainerMode.PIP) return true;
    return false;
  }, [isFullscreen, disableResize, mode]);

  const handleResize: RndResizeCallback = useCallback(
    (e, direction, ref, delta, updatedPosition) => {
      const updatedSize: Size = { height: ref.offsetHeight, width: ref.offsetWidth };
      updateSize({ mode: PipContainerMode.PIP, size: updatedSize });
      updatePosition({ mode: PipContainerMode.PIP, position: updatedPosition });
    },
    [updateSize, updatePosition]
  );

  useImperativeHandle<PipContainerInstance, PipContainerInstance>(
    ref,
    () => ({
      parentRef: rootElRef,
      bodyRef: bodyElRef,
      requestFullScreen,
      exitFullscreen,
      requestModeChange,
      resetPosition: resetPipModePosition,
      resetSize: resetPipModeSize,
    }),
    [requestFullScreen, exitFullscreen, requestModeChange, resetPipModePosition, resetPipModeSize]
  );

  return {
    rootElRef,
    bodyElRef,
    mode,
    position,
    size,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    lockAspectRatio,
    lockAspectRatioExtraHeight,
    lockAspectRatioExtraWidth,
    disableDragging,
    handleDragStop,
    disableResizing,
    handleResize,
  };
};

export default usePipContainer;
