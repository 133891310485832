import { Dictionary, Draft } from '@reduxjs/toolkit';

import { API } from '../shared/api-responses';
import { AttachmentType, CommentContext, CourseUser } from '../shared/types';
import { Comment, CommentContextData } from './types';

export function getIsSameCommentContext(c1: CommentContextData | null, c2: CommentContextData | null) {
  if (!c1 && !c2) return true;
  if (!c1 || !c2) return false;
  if (c1.id !== c2.id) return false;
  if (c1.type === CommentContext.ASSIGNMENT || c2.type === CommentContext.ASSIGNMENT) {
    if (c1.subType !== c2.subType) return false;
  }
  return c1.type === c2.type;
}

export function createCommentFactory({
  comment,
  isFirstCommentInGroup,
}: {
  comment: API.Comment;
  isFirstCommentInGroup: boolean;
}): Comment {
  const result: Comment = {
    id: comment._id,
    message: comment.details.message,
    image: null,
    files: [],
    stats: {
      likes: comment.stats.numStars,
      thanks: comment.stats.numHelps,
      awards: comment.stats.awards,
    },
    reactions: {
      likedBy: null,
      thankedBy: null,
      awards: comment.stats.awards,
    },
    isFirstCommentInGroup,
    isDeleted: Boolean(comment.removed),
    createdBy: comment.details.createdBy,
    createdOn: comment.details.createdOn,
  };

  if (comment.details.hasAttachment === 1) {
    switch (comment.details.attachmentType) {
      case AttachmentType.FILE:
        result.files = comment.details.attachments;
        break;
      case AttachmentType.IMAGE:
        result.image = comment.details.imageUrl;
        break;
    }
  }

  return result;
}

export function getIsFirstCommentInGroup(
  prevComment: { createdBy: CourseUser; createdOn: UnixTime },
  newComment: { createdBy: CourseUser; createdOn: UnixTime }
): boolean {
  const isSameUser = prevComment.createdBy.userId === newComment.createdBy.userId;
  const isWithinTimeRange = newComment.createdOn - prevComment.createdOn < 3600; // is within 1 hour
  return !isSameUser || !isWithinTimeRange;
}

/**
 * Appends `list2` behind `list1` and also merges the comment
 * group at the junction of `list1` and `list2`, if it can be merged
 */
export function joinCommentList({
  commentsById,
  list1,
  list2,
}: {
  commentsById: Draft<Dictionary<Comment>>;
  /** comment ids in increasing order of time, i.e. latest in last */
  list1: MongoId[];
  /** comment ids in increasing order of time, i.e. latest in last */
  list2: MongoId[];
}) {
  /** Oldest comment of list 2 */
  const firstComment = commentsById[list2.at(0) || ''];

  /** Latest comment from list 1 */
  const lastComment = commentsById[list1.at(-1) || ''];

  if (firstComment && lastComment) {
    // update if this comment should remain the first comment in the group
    firstComment.isFirstCommentInGroup = getIsFirstCommentInGroup(lastComment, firstComment);
  } else if (firstComment && !list1.length) {
    // make the comment first in group if list1 is empty
    firstComment.isFirstCommentInGroup = true;
  }

  return [...list1, ...list2];
}
