import { useTranslation } from 'react-i18next';

import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  OpenInNew as OpenInNewIcon,
  OpenWith as OpenWithIcon,
  PictureInPictureAlt as PictureInPictureIcon,
  VideoLabel as VideoLabelIcon,
} from '@mui/icons-material';

import Tooltip from '../../components/Tooltip';
import { i18nNS } from '../../i18n';
import { PipContainerMode } from '../../pip-container';
import PipHeader from '../../pip-container/PipHeader';
import { IconButton, zoomAppHeaderClasses } from './styles';
import useZoomAppHeaderVM from './vm';

interface Props {
  /** @default true */
  canPopIn?: boolean;
}

const ZoomAppHeader = ({ canPopIn = true }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.ONLINE_MEETING]);

  const {
    container,
    exitFullscreen,
    handleMinimise,
    handlePopOut,
    pipMode,
    requestEmbeddedMode,
    isFullScreen,
    requestFullScreen,
  } = useZoomAppHeaderVM();

  return (
    <PipHeader>
      {!isFullScreen && (
        <>
          {pipMode === PipContainerMode.PIP && (
            <Tooltip PopperProps={{ container }} title={t('move', { ns: i18nNS.GLOSSARY })}>
              <IconButton
                size="small"
                className={zoomAppHeaderClasses.dragHandle}
                startIcon={<OpenWithIcon aria-hidden />}
              />
            </Tooltip>
          )}
          {pipMode === PipContainerMode.EMBEDDED && (
            <Tooltip PopperProps={{ container }} title={t('pop_out', { ns: i18nNS.ONLINE_MEETING })}>
              <IconButton
                size="small"
                onClick={handlePopOut}
                startIcon={<PictureInPictureIcon aria-hidden />}
              />
            </Tooltip>
          )}
          {pipMode === PipContainerMode.PIP && canPopIn && (
            <Tooltip PopperProps={{ container }} title={t('pop_in', { ns: i18nNS.ONLINE_MEETING })}>
              <IconButton
                size="small"
                onClick={requestEmbeddedMode}
                startIcon={<OpenInNewIcon aria-hidden />}
              />
            </Tooltip>
          )}
          {pipMode === PipContainerMode.PIP && (
            <Tooltip
              PopperProps={{ container }}
              title={t('collapse_to_corner', { ns: i18nNS.ONLINE_MEETING })}
            >
              <IconButton size="small" onClick={handleMinimise} startIcon={<VideoLabelIcon aria-hidden />} />
            </Tooltip>
          )}
          <Tooltip PopperProps={{ container }} title={t('full_screen', { ns: i18nNS.ONLINE_MEETING })}>
            <IconButton size="small" onClick={requestFullScreen} startIcon={<FullscreenIcon aria-hidden />} />
          </Tooltip>
        </>
      )}
      {isFullScreen && (
        <Tooltip PopperProps={{ container }} title={t('exit_full_screen', { ns: i18nNS.ONLINE_MEETING })}>
          <IconButton size="small" onClick={exitFullscreen} startIcon={<FullscreenExitIcon aria-hidden />} />
        </Tooltip>
      )}
    </PipHeader>
  );
};

export default ZoomAppHeader;
