import { useState } from 'react';

import { Star as StarIcon } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

import Button, { Props as ButtonProps } from '../../components/Button/index';

const ButtonRenderer = ({
  size,
  color,
  variant,
  startIcon,
  endIcon,
  children,
  disabled,
}: Pick<ButtonProps, 'size' | 'color' | 'variant' | 'startIcon' | 'endIcon' | 'children' | 'disabled'>) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Button
      size={size}
      color={color}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      isLoading={loading}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};

const ButtonRow = (
  props: Pick<ButtonProps, 'color' | 'variant' | 'startIcon' | 'endIcon' | 'children' | 'disabled'>
) => {
  const sizes: ButtonProps['size'][] = ['xLarge', 'large', 'medium', 'small', 'xSmall'];

  return (
    <TableRow>
      {sizes.map((size) => (
        <TableCell key={size} sx={{ border: 'none' }}>
          <ButtonRenderer {...props} size={size}>
            {props.children}
          </ButtonRenderer>
        </TableCell>
      ))}
    </TableRow>
  );
};

const Buttons = ({ color, variant, disabled }: Pick<ButtonProps, 'color' | 'variant' | 'disabled'>) => {
  return (
    <Table>
      <TableBody>
        <ButtonRow
          color={color}
          variant={variant}
          disabled={disabled}
          startIcon={<StarIcon />}
          endIcon={<StarIcon />}
        >
          Button
        </ButtonRow>
        <ButtonRow color={color} variant={variant} disabled={disabled} startIcon={<StarIcon />}>
          Button
        </ButtonRow>
        <ButtonRow color={color} variant={variant} disabled={disabled} endIcon={<StarIcon />}>
          Button
        </ButtonRow>
        <ButtonRow color={color} variant={variant} disabled={disabled}>
          Button
        </ButtonRow>
      </TableBody>
    </Table>
  );
};

export default Buttons;
