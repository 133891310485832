import { ZOOM_MEETING_AREA } from '../../constants';
import { PipContainer } from '../../pip-container';
import { zoomAppFooterHeight } from '../ZoomAppFooter/ZoomAppFooterLayout/styles';
import ZoomMeeting from '../ZoomMeeting';
import useZoomMeetingPipVM from './vm';

const ZoomMeetingPip = () => {
  const { handlePipModeChange, id, ref, isOpen } = useZoomMeetingPipVM();

  if (!isOpen) return null;

  return (
    <PipContainer
      id={id}
      ref={ref}
      embedTargetSelector={`#${ZOOM_MEETING_AREA}`}
      footerHeight={zoomAppFooterHeight}
      onModeChange={handlePipModeChange}
      aspectRatio={1.3}
    >
      <ZoomMeeting />
    </PipContainer>
  );
};

export default ZoomMeetingPip;
