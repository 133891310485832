import { useCallback, useEffect, useRef } from 'react';

type Callback = () => void;

/**
 * React hook for delaying calls with time returns callback to use for cancelling
 * @param callback function to call. if you create a new callback each render, then previous callback will be cancelled on render.
 * @param timeout delay, ms (default: immediately put into JS Event Queue)
 */
export const useTimeout = (callback: Callback, timeout: number = 0): Callback => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    timeoutIdRef.current = setTimeout(callback, timeout);
    return cancel;
  }, [callback, timeout, cancel]);

  return cancel;
};

export function useInterval(callback: () => void, delay: number | null, maxRetryCount = 0) {
  const savedCallback = useRef(callback);
  const savedDelay = useRef(delay);

  const intervalId = useRef<NodeJS.Timer | undefined>(undefined);
  let numAttempts = useRef(0);

  const stop = useCallback(() => {
    if (!intervalId.current) return;

    clearInterval(intervalId.current);
    intervalId.current = undefined;
  }, []);

  const start = useCallback(
    (delay: number | null = savedDelay.current) => {
      // stop interval if already running
      if (intervalId.current) stop();

      /**
       * Don't schedule if no delay is specified.
       * Note: 0 is a valid value for delay.
       */
      if (!delay || delay < 0) return;
      numAttempts.current = 0;

      savedDelay.current = delay;
      intervalId.current = setInterval(() => {
        if (maxRetryCount > 0 && numAttempts.current > maxRetryCount) {
          stop();
          return;
        }
        savedCallback.current();
        numAttempts.current += 1;
      }, delay);
    },
    [stop, maxRetryCount]
  );

  // Remember the latest callback if it changes.
  useEffect(
    function updateCallback() {
      savedCallback.current = callback;
    },
    [callback]
  );

  useEffect(
    function initInterval() {
      // do nothing if already running
      if (intervalId.current) return;

      // auto start the timer
      start();

      // stop timer on component unmount or rerender
      return () => {
        stop();
      };
    },
    [start, stop]
  );

  useEffect(
    function resetInterval() {
      savedDelay.current = delay;

      // do nothing if timer not running
      if (!intervalId.current) return;

      start(delay);
    },
    [delay, start, stop]
  );

  return { start, stop, numAttempts: numAttempts.current };
}
