import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, FormGroup } from '@mui/material';

import Checkbox from '../../components/HookFormControls/Checkbox';

interface FormValues {
  apple: boolean;
  orange: boolean;
  banana: boolean;
  kiwi: boolean;
}

const FormCheckbox = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      apple: false,
      orange: true,
      banana: true,
      kiwi: true,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data, 'data >>>>>');
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormGroup>
        <Checkbox control={control} name="apple" label="🍏" />
        <br />
        <Checkbox control={control} name="orange" label="🍊" />
        <br />
        <Checkbox control={control} name="banana" label="🍌" disabled />
        <br />
        <Checkbox control={control} name="kiwi" label="Kiwi" color="#ff0000" />
        <br />
        <Checkbox control={control} name="kiwi" label="Kiwi" disabled color="#ff0000" />
        <br />
        <Checkbox control={control} name="kiwi" label="Kiwi" disabled color="#ff0000" hasColorWhenDisabled />
      </FormGroup>
      <br />
      <br />
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};

export default FormCheckbox;
