import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Stack, styled } from '@mui/material';

import routes from '../../pages/routes';
import { PipContainer, PipContainerType, usePipContainer } from '../../pip-container';
import { pipContainerClasses } from '../../pip-container/PipContainer/styles';

const StyledPipContainer = styled(PipContainer)(({ theme }) => ({
  [`& .${pipContainerClasses.resizeAndDragRoot}`]: {
    backgroundColor: 'skyblue',
  },
}));

export const usePlaygroundPipContainer = () => {
  return usePipContainer({
    id: 'playground_pip',
    type: PipContainerType.MEDIA_PLAYER,
  });
};

const PipContainerPlayground = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Stack style={{ height: '100vh' }}>
      <Link to={routes.playground.path}>Go back</Link>
      <Stack flex={1} direction="row" gap={5} overflow="hidden">
        <div style={{ overflow: 'auto', height: '100%' }}>
          <button onClick={() => setActiveTabIndex(0)}>First tab</button>
          <button onClick={() => setActiveTabIndex(1)}>Second tab</button>
          {activeTabIndex === 0 && (
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam ullam placeat nisi quae
                culpa quasi inventore perspiciatis unde, eligendi ipsum vero tempore atque nam minima
                voluptatem qui, ab consectetur nulla. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quisquam sequi numquam eos nostrum voluptatem debitis, repudiandae aperiam hic porro dicta!
                Deserunt exercitationem odit dignissimos, dolorum dicta reiciendis earum quam molestias? Lorem
                ipsum dolor sit, amet consectetur adipisicing elit. Dolore error amet expedita itaque
                consectetur iure, esse ducimus excepturi molestiae eaque eligendi nobis, fuga ut quisquam
                soluta sit cupiditate magnam aut? Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Rerum dolorem ipsa vitae officiis expedita! Quas repudiandae molestias officia ut harum
                necessitatibus, aspernatur sequi tenetur quasi architecto voluptates quisquam, obcaecati
                voluptate? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate, architecto
                iusto, sunt ea nemo laboriosam recusandae perferendis fuga reprehenderit vel soluta, magnam
                error voluptate expedita tenetur inventore saepe consequuntur dignissimos! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Explicabo, consequatur optio quod ullam, veniam in eos
                recusandae et aliquam officiis libero eveniet, corrupti repellat illum! Iure mollitia
                perspiciatis aliquam tempore! Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                ducimus nostrum expedita at quia saepe labore officiis ad accusamus non commodi aperiam,
                provident eius. Rerum suscipit dignissimos dolorum eum minima!
              </p>
              <div
                id="pip-container-playground-target"
                style={{
                  width: 400,
                  backgroundColor: '#fff',
                  marginLeft: 50,
                  paddingTop: 200,
                  paddingBottom: 200,
                }}
              >
                Pip container's embed target element
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam ullam placeat nisi quae
                culpa quasi inventore perspiciatis unde, eligendi ipsum vero tempore atque nam minima
                voluptatem qui, ab consectetur nulla. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quisquam sequi numquam eos nostrum voluptatem debitis, repudiandae aperiam hic porro dicta!
                Deserunt exercitationem odit dignissimos, dolorum dicta reiciendis earum quam molestias? Lorem
                ipsum dolor sit, amet consectetur adipisicing elit. Dolore error amet expedita itaque
                consectetur iure, esse ducimus excepturi molestiae eaque eligendi nobis, fuga ut quisquam
                soluta sit cupiditate magnam aut? Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Rerum dolorem ipsa vitae officiis expedita! Quas repudiandae molestias officia ut harum
                necessitatibus, aspernatur sequi tenetur quasi architecto voluptates quisquam, obcaecati
                voluptate? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate, architecto
                iusto, sunt ea nemo laboriosam recusandae perferendis fuga reprehenderit vel soluta, magnam
                error voluptate expedita tenetur inventore saepe consequuntur dignissimos! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Explicabo, consequatur optio quod ullam, veniam in eos
                recusandae et aliquam officiis libero eveniet, corrupti repellat illum! Iure mollitia
                perspiciatis aliquam tempore! Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                ducimus nostrum expedita at quia saepe labore officiis ad accusamus non commodi aperiam,
                provident eius. Rerum suscipit dignissimos dolorum eum minima!
              </p>
            </div>
          )}
          {activeTabIndex === 1 && (
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam ullam placeat nisi quae
                culpa quasi inventore perspiciatis unde, eligendi ipsum vero tempore atque nam minima
                voluptatem qui, ab consectetur nulla. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quisquam sequi numquam eos nostrum voluptatem debitis, repudiandae aperiam hic porro dicta!
                Deserunt exercitationem odit dignissimos, dolorum dicta reiciendis earum quam molestias? Lorem
                ipsum dolor sit, amet consectetur adipisicing elit. Dolore error amet expedita itaque
                consectetur iure, esse ducimus excepturi molestiae eaque eligendi nobis, fuga ut quisquam
                soluta sit cupiditate magnam aut? Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Rerum dolorem ipsa vitae officiis expedita! Quas repudiandae molestias officia ut harum
                necessitatibus, aspernatur sequi tenetur quasi architecto voluptates quisquam, obcaecati
                voluptate? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate, architecto
                iusto, sunt ea nemo laboriosam recusandae perferendis fuga reprehenderit vel soluta, magnam
                error voluptate expedita tenetur inventore saepe consequuntur dignissimos! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Explicabo, consequatur optio quod ullam, veniam in eos
                recusandae et aliquam officiis libero eveniet, corrupti repellat illum! Iure mollitia
                perspiciatis aliquam tempore! Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                ducimus nostrum expedita at quia saepe labore officiis ad accusamus non commodi aperiam,
                provident eius. Rerum suscipit dignissimos dolorum eum minima!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam ullam placeat nisi quae
                culpa quasi inventore perspiciatis unde, eligendi ipsum vero tempore atque nam minima
                voluptatem qui, ab consectetur nulla. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quisquam sequi numquam eos nostrum voluptatem debitis, repudiandae aperiam hic porro dicta!
                Deserunt exercitationem odit dignissimos, dolorum dicta reiciendis earum quam molestias? Lorem
                ipsum dolor sit, amet consectetur adipisicing elit. Dolore error amet expedita itaque
                consectetur iure, esse ducimus excepturi molestiae eaque eligendi nobis, fuga ut quisquam
                soluta sit cupiditate magnam aut? Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Rerum dolorem ipsa vitae officiis expedita! Quas repudiandae molestias officia ut harum
                necessitatibus, aspernatur sequi tenetur quasi architecto voluptates quisquam, obcaecati
                voluptate? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate, architecto
                iusto, sunt ea nemo laboriosam recusandae perferendis fuga reprehenderit vel soluta, magnam
                error voluptate expedita tenetur inventore saepe consequuntur dignissimos! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Explicabo, consequatur optio quod ullam, veniam in eos
                recusandae et aliquam officiis libero eveniet, corrupti repellat illum! Iure mollitia
                perspiciatis aliquam tempore! Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                ducimus nostrum expedita at quia saepe labore officiis ad accusamus non commodi aperiam,
                provident eius. Rerum suscipit dignissimos dolorum eum minima!
              </p>
            </div>
          )}
        </div>
        <div style={{ overflow: 'auto', height: '100%' }}>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam ullam placeat nisi quae culpa
            quasi inventore perspiciatis unde, eligendi ipsum vero tempore atque nam minima voluptatem qui, ab
            consectetur nulla. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam sequi numquam
            eos nostrum voluptatem debitis, repudiandae aperiam hic porro dicta! Deserunt exercitationem odit
            dignissimos, dolorum dicta reiciendis earum quam molestias? Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Dolore error amet expedita itaque consectetur iure, esse ducimus
            excepturi molestiae eaque eligendi nobis, fuga ut quisquam soluta sit cupiditate magnam aut?
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum dolorem ipsa vitae officiis
            expedita! Quas repudiandae molestias officia ut harum necessitatibus, aspernatur sequi tenetur
            quasi architecto voluptates quisquam, obcaecati voluptate? Lorem ipsum, dolor sit amet consectetur
            adipisicing elit. Cupiditate, architecto iusto, sunt ea nemo laboriosam recusandae perferendis
            fuga reprehenderit vel soluta, magnam error voluptate expedita tenetur inventore saepe
            consequuntur dignissimos! Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo,
            consequatur optio quod ullam, veniam in eos recusandae et aliquam officiis libero eveniet,
            corrupti repellat illum! Iure mollitia perspiciatis aliquam tempore! Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Id ducimus nostrum expedita at quia saepe labore officiis ad
            accusamus non commodi aperiam, provident eius. Rerum suscipit dignissimos dolorum eum minima!
          </p>
        </div>
      </Stack>
      <StyledPipContainer
        id="my-new-pip"
        embedTargetSelector="#pip-container-playground-target"
        // headerHeight={40}
        footerHeight={60}
      >
        <div>
          <div style={{ height: 40, backgroundColor: 'limegreen' }}>Header</div>
          <p>
            Hello I am inside pip. Cupiditate, architecto iusto, sunt ea nemo laboriosam recusandae
            perferendis fuga reprehenderit vel soluta, magnam error voluptate expedita tenetur inventore saepe
            consequuntur dignissimos!
          </p>
          {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo, consequatur optio quod
              ullam, veniam in eos recusandae et aliquam officiis libero eveniet, corrupti repellat illum!
              Iure mollitia perspiciatis aliquam tempore! Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Id ducimus nostrum expedita at quia saepe labore officiis ad accusamus non commodi
              aperiam, provident eius.
            </p> */}
          <div style={{ height: 60, backgroundColor: 'olivedrab' }}>This is Footer of pip</div>
        </div>
      </StyledPipContainer>
    </Stack>
  );
};

export default PipContainerPlayground;
