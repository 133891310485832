import { useSelector } from 'react-redux';

import { styled } from '@mui/material';

import { selectAuthSession } from '../auth/store/selectors';
import UserAvatar from '../components/UserAvatar/index';

const UserAvatarPlayground = () => {
  const ContentRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8),
  }));

  const session = useSelector(selectAuthSession);
  if (!session) return <h1>Session not Present</h1>;

  return (
    <ContentRoot>
      <UserAvatar avatarId={session?.avatar}></UserAvatar>
      <UserAvatar avatarId={''}>A</UserAvatar>
      <UserAvatar avatarId={''} name={'Bob Acadly'}>
        A
      </UserAvatar>
      <UserAvatar avatarId={''}>A</UserAvatar>
    </ContentRoot>
  );
};
export default UserAvatarPlayground;
