import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, Stack } from '@mui/material';

import Slider from '../../components/HookFormControls/Slider';

interface FormValues {
  slider1: number;
  slider2: number;
  slider3: number;
  slider4: number;
  slider5: number;
  multiSlider1: [number, number];
  multiSlider2: [number, number];
  multiSlider3: [number, number];
  multiSlider4: [number, number];
  multiSlider5: [number, number];
}

const FormSlider = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      slider1: 20,
      slider2: 20,
      slider3: 20,
      slider4: 20,
      slider5: 20,
      multiSlider1: [20, 40],
      multiSlider2: [20, 40],
      multiSlider3: [20, 40],
      multiSlider4: [20, 40],
      multiSlider5: [20, 40],
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data, 'data >>>>>');
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack direction="column" gap={4}>
        <Slider control={control} name="slider1" />
        <Slider control={control} name="slider2" color="primary" />
        <Slider control={control} name="slider3" color="success" />
        <Slider control={control} name="slider4" color="warning" />
        <Slider control={control} name="slider5" color="error" />
        <Slider control={control} name="multiSlider1" />
        <Slider control={control} name="multiSlider2" color="primary" />
        <Slider control={control} name="multiSlider3" color="success" />
        <Slider control={control} name="multiSlider4" color="warning" />
        <Slider control={control} name="multiSlider5" color="error" />
      </Stack>
      <br />
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};

export default FormSlider;
