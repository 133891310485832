import { useCallback, useContext, useMemo } from 'react';

import { PipContainerContext } from './Context';
import { BasePipContainerData, PipContainerContextType, PipContainerData, PipContainerMode } from './types';

export function usePipContainer<T>({ id, type }: BasePipContainerData) {
  const { open, close, getPipContainerData, getRefById, updatePipContainerData } =
    useContext<PipContainerContextType>(PipContainerContext);

  const ref = useMemo(() => getRefById(id), [getRefById, id]);

  const getIsOpen = useCallback(
    function getIsOpen() {
      const data = getPipContainerData(id);
      return data?.isOpen ?? false;
    },
    [getPipContainerData, id]
  );

  const closePip = useCallback(
    function closePip() {
      close(id);
    },
    [close, id]
  );

  const openPip = useCallback(
    function openPip(data: T) {
      closePip();
      open({ id, type, ...data });
    },
    [closePip, id, open, type]
  );

  const getContextData = useCallback(
    function getContextData() {
      return getPipContainerData(id) as (PipContainerData & T) | undefined;
    },
    [getPipContainerData, id]
  );

  const updateContextData = useCallback(
    function updateContextData(data: Partial<T>) {
      return updatePipContainerData(id, data);
    },
    [id, updatePipContainerData]
  );

  const changeMode = useCallback(
    function changeMode(mode: PipContainerMode) {
      if (!ref.current) return;
      ref.current.requestModeChange(mode);
    },
    [ref]
  );

  return useMemo(() => {
    return {
      id,
      ref,
      get isOpen() {
        return getIsOpen();
      },
      open: openPip,
      close: closePip,
      getContextData,
      updateContextData,
      changeMode,
    };
  }, [changeMode, closePip, getContextData, getIsOpen, id, openPip, ref, updateContextData]);
}
