const sampleRichTexts = [
  `<formula format="tex" display="block" id="62daf21f484d6225682a2bb3" ach="190" acw="555" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/a628994c-ae05-4f1d-a2bf-48e3c2757d2d-xxl.png">\\binom{n}{k} = \\frac{n!}{k!(n-k)!}</formula><br>True or false&nbsp;<formula format="tex" display="inline" id="5ed496519973ed274ee0fe16" ach="90" acw="200" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/4ec68d8e-0d66-41d8-a0ef-ecad37e6de98-xxl.png">y=x^2</formula>&nbsp;<br><br><img src="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/i/3520a627-5488-480d-ad20-7e80940372b3.png" acw="350" ach="110">`,

  `This is sample image comment<br><br><formula format="tex" display="inline" id="61c077a6b6d8ac30f9ad44b1" ach="65" acw="315" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/65e67efa-0510-4725-8d5f-da45237ac437-xxl.png">v = u + at</formula>&nbsp;<br><br><br><formula format="tex" display="inline" id="61c077e9b6d8ac30f9ad44b2" ach="155" acw="430" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/dbc4fba9-36e1-47aa-9dc6-b86df2b22d8f-xxl.png">s = ut + \\frac{1}{2}at^2</formula>&nbsp;`,

  `Test this plain text <b>bold text</b> <i>italics text </i><u>underlined text</u> <b><i>bold and italics text</i></b> <b><u>bold and underlined</u></b> <i><u>italics and underlined</u></i> <b><i><u>bold italic and underlined.</u></i></b><br>This is newline.<br> <formula format="tex" acw="250" ach="80" display="inline" id="5e00a3f3a4c37974683fc8ec" url="https://acadly-test.s3.amazonaws.com/c/5e0063138a7ddd53a7708942/math/2243b915-67bd-413e-bf61-26962897b447-xxl.png">e=mc^2</formula>&nbsp; - inline formula<br><br><formula format="tex" acw="430" ach="155" display="block" id="5e00a43ea4c37974683fc8ed" url="https://acadly-test.s3.amazonaws.com/c/5e0063138a7ddd53a7708942/math/a241ba58-9cd6-4890-9aaf-17af6ce780f8-xxl.png">s=ut + \\dfrac{1}{2}at^2</formula><br><br>Above equation is displayed "block".<br><br><br><img src="https://acadly-test.s3.amazonaws.com/c/5e0063138a7ddd53a7708942/i/0f29f8d9-ffa1-45eb-b10c-5508562a8432.jpg" ach="545" acw="746"><br><br>One more image<br><br><br><br><img src="https://acadly-test.s3.amazonaws.com/c/5e0063138a7ddd53a7708942/i/ba995304-0156-439a-a024-a6cfc9f80b47.jpg" ach="853" acw="1280">`,

  `<b>Nested formatting </b><i><b>tags </b></i><u><i><b>it can have images </b></i></u><br><br><img src="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/i/ad602f83-640e-401a-9aef-1f7424161faa.jpeg" ach="2160" acw="3840"><u><i><b></b></i></u><br><br><u><i><b>Also </b></i></u><i><b>it can have formulae too&nbsp; </b></i><formula format="tex" acw="310" ach="145" display="inline" id="62e10de8484d6225682a2c85" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/8abfac6b-76ea-4695-9f9f-92c372709243-xxl.png">e=mc^2 \\frac{a}{b}</formula><i><b></b></i><i><b> </b></i><br><i><b>And </b></i><b>here is an example of block formula </b><br><br><formula format="tex" acw="555" ach="190" display="block" id="62e10e65484d6225682a2c86" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/c29effa8-8389-4e6e-b1cb-653c8275f283-xxl.png">\\binom{n}{k}=\\frac{n!}{k!(n-k)!}</formula><b></b><br><br><b>Now let's break root node</b><br>This is a plain text.`,

  `Some text <b>This is bold</b>&nbsp;<i>this <b>is</b> italic&nbsp;<formula format="tex" display="inline" id="62ea785c484d6225682a2de0" ach="80" acw="250" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/7caac2c5-023e-40ae-aacf-19620320b8c1-xxl.png">e=mc^2</formula>&nbsp;</i>sdfdsf&nbsp;<formula format="tex" display="block" id="62ea789a484d6225682a2de1" ach="155" acw="430" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/3488e531-b0fe-427b-bc2a-c09e05d72249-xxl.png">s=ut+\\frac{1}{2}at^2</formula>Now ascii math&nbsp;<formula format="am" display="inline" id="62ea78cc484d6225682a2de2" ach="215" acw="700" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/496bba7a-5463-46bc-b790-ff4d3715994f-xxl.png"> sum_(i=1)^n i^3=((n(n+1))/2)^2 </formula>&nbsp;<formula format="am" display="block" id="62ea78e6484d6225682a2de3" ach="215" acw="700" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/c35d42c3-3586-42e4-b722-bf8fbd13f91a-xxl.png"> sum_(i=1)^n i^3=((n(n+1))/2)^2 </formula>Now this is MathML&nbsp;<formula format="mml" display="inline" id="62ea790f484d6225682a2de4" ach="80" acw="480" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/d254346e-ef98-4437-bf14-ebfde7265ebb-xxl.png"><math display="inline"><mrow>
  <mrow>
  <msup>
    <mi>x</mi>
    <mn>2</mn>
  </msup>
  <mo>+</mo>
  <mrow>
    <mn>4</mn>
    <mo>⁢</mo>
    <mi>x</mi>
  </mrow>
  <mo>+</mo>
  <mn>4</mn>
</mrow>
<mo>=</mo>
<mn>0</mn>
</mrow></math></formula>&nbsp;<formula format="mml" display="block" id="62ea7935484d6225682a2de5" ach="165" acw="460" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/fdd2181b-d52a-4ac2-a9ed-d7b4e45852e4-xxl.png"><math display="block">
<mrow>
<mi>x</mi>
<mo>=</mo>
<mfrac>
  <mrow>
    <mrow>
      <mo>-</mo>
      <mi>b</mi>
    </mrow>
    <mo>±</mo>
    <msqrt>
      <mrow>
        <msup>
          <mi>b</mi>
          <mn>2</mn>
        </msup>
        <mo>-</mo>
        <mrow>
          <mn>4</mn>
          <mo>⁢</mo>
          <mi>a</mi>
          <mo>⁢</mo>
          <mi>c</mi>
        </mrow>
      </mrow>
    </msqrt>
  </mrow>
  <mrow>
    <mn>2</mn>
    <mo>⁢</mo>
    <mi>a</mi>
  </mrow>
</mfrac>
</mrow>
</math></formula>End`,
];

export default sampleRichTexts;
