import { SyntheticEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/base';

import AcadlyDropdown, { Props as AcadlyDropdownProps } from '../FormControls/Dropdown';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useDropdownVM = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });
  return { controller };
};

type Props<
  T extends FieldValues,
  OptionType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = VMProps<T> &
  DistributiveOmit<
    AcadlyDropdownProps<OptionType, Multiple, DisableClearable, FreeSolo>,
    'ref' | 'inputRef' | 'name' | 'value' | 'onChange' | 'onBlur'
  >;

const Dropdown = <
  T extends FieldValues,
  OptionType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  control,
  name,
  defaultValue,
  helperText,
  ...props
}: Props<T, OptionType, Multiple, DisableClearable, FreeSolo>) => {
  const { controller } = useDropdownVM({ control, name, defaultValue });
  const { field, fieldState } = controller;
  const { ref: inputRef, value, onChange, onBlur } = field;
  const { error } = fieldState;

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    value: OptionType | OptionType[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionType | OptionType[]> | undefined
  ) => {
    onChange(value);
  };

  return (
    <AcadlyDropdown
      {...props}
      ref={inputRef}
      value={value}
      // FIXME: fix this typing issue
      // @ts-ignore
      onChange={handleChange}
      onBlur={onBlur}
      error={Boolean(error)}
      aria-invalid={Boolean(error)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default Dropdown;
