import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@mui/material';

import RadioOption from '../../components/FormControls/RadioGroup/RadioOption';
import RadioGroup from '../../components/HookFormControls/RadioGroup';

interface FormValues {
  gender: 'male' | 'female' | 'notDisclosed' | '';
  uncheckable: 'true' | 'false' | null;
}

const FormRadioGroup = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      gender: 'male',
      uncheckable: 'true',
    },
    mode: 'all',
  });

  const gender = watch('gender');
  console.log({ errors, gender });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data, 'data >>>>>');
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RadioGroup control={control} name="gender" label="Gender" fullWidth>
        <RadioOption value="male">Male</RadioOption>
        <span>OR</span>
        <RadioOption value="female">Female</RadioOption>
        <RadioOption value="notDisclosed" disabled>
          Do not disclose
        </RadioOption>
      </RadioGroup>
      <br />
      <br />
      <RadioGroup control={control} name="uncheckable" label="Uncheckable" fullWidth canUncheck>
        <RadioOption value="true">True</RadioOption>
        <RadioOption value="false">False</RadioOption>
      </RadioGroup>
      <br />
      <br />
      <RadioGroup
        control={control}
        name="uncheckable"
        label="Custom colors (change above uncheckable radios to see changes in these disabled radio colors)"
        fullWidth
      >
        <RadioOption value="true" color="#ff0000" disabled>
          True
        </RadioOption>
        <RadioOption value="false" color="#ff0000" hasColorWhenDisabled disabled>
          False
        </RadioOption>
      </RadioGroup>
      <br />
      <br />
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};

export default FormRadioGroup;
