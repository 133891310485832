import { RefObject } from 'react';

export enum PipContainerMode {
  PIP = 'pip',
  EMBEDDED = 'embedded',
}

export interface Position {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface PipContainerInstance {
  parentRef: RefObject<HTMLDivElement>;
  bodyRef: RefObject<HTMLDivElement>;
  requestFullScreen(): void;
  exitFullscreen(): void;
  /**
   * Tries to change the current mode of pip-container, if could
   * not change the mode then the returned promise is resolved to `false`.
   *
   * Failure cases:
   *
   * 1. Browser permission
   *
   *     Rejected by browser as user has not given permission to browser
   *     for fullscreen mode.
   *
   * 2. Embed target not found
   *
   *     If requested embedded mode but the target element is not
   *     found in the DOM or is yet to render.
   *     In this case, Pip container will fallback to PIP Mode
   *     till embed target is available.
   *
   * @param mode requested mode
   */
  requestModeChange(mode: PipContainerMode): void;
  resetPosition(): void;
  resetSize(): void;
}

export enum PipContainerType {
  MEDIA_PLAYER = 'media_player',
  ZOOM_MEETING = 'zoom_meeting',
}

export interface BasePipContainerData {
  id: string;
  type: PipContainerType;
}

export interface PipContainerData extends BasePipContainerData {
  isOpen: boolean;
}

export interface PipContainerState {
  byId: Record<string, PipContainerData | undefined>;
}

export interface PipContainerContextType extends PipContainerState {
  open: <T extends BasePipContainerData = BasePipContainerData>(data: T) => void;
  close: (containerId: string) => void;
  getPipContainerData: <T extends PipContainerData = PipContainerData>(containerId: string) => T | undefined;
  getRefById: (id: string) => RefObject<PipContainerInstance>;
  updatePipContainerData: <T extends {}>(containerId: string, updates: Partial<T>) => void;
}
