import { forwardRef, ReactNode, Ref } from 'react';
import { Rnd } from 'react-rnd';

import { Portal } from '@mui/material';

import clsx from 'clsx';

import { PipContainerInstance, PipContainerMode } from '../types';
import { PipBody, pipContainerClasses, Root } from './styles';
import usePipContainer, { UsePipContainer } from './vm';

export interface PipContainerProps extends UsePipContainer {
  className?: string;
  classes?: Partial<typeof pipContainerClasses>;
  children: ReactNode;
}

const PipContainer = (
  { className, classes, children, ...vmOptions }: PipContainerProps,
  ref: Ref<PipContainerInstance>
) => {
  const {
    rootElRef,
    bodyElRef,
    mode,
    position,
    size,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    lockAspectRatio,
    lockAspectRatioExtraHeight,
    lockAspectRatioExtraWidth,
    disableDragging,
    handleDragStop,
    disableResizing,
    handleResize,
  } = usePipContainer(vmOptions, ref);

  const enableSideResize = !lockAspectRatio;
  const enableCornerResize = Boolean(lockAspectRatio);

  return (
    <Portal>
      <Root
        ref={rootElRef}
        className={clsx(className, classes?.root, pipContainerClasses.root, {
          [pipContainerClasses.modePip]: mode === PipContainerMode.PIP,
          [classes?.modePip ?? '']: mode === PipContainerMode.PIP,
          [pipContainerClasses.modeEmbedded]: mode === PipContainerMode.EMBEDDED,
          [classes?.modeEmbedded ?? '']: mode === PipContainerMode.EMBEDDED,
        })}
      >
        <Rnd
          bounds="parent"
          position={position}
          size={size}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          minHeight={minHeight}
          minWidth={minWidth}
          lockAspectRatio={lockAspectRatio}
          lockAspectRatioExtraHeight={lockAspectRatioExtraHeight}
          lockAspectRatioExtraWidth={lockAspectRatioExtraWidth}
          disableDragging={disableDragging}
          onDragStop={handleDragStop}
          enableResizing={
            disableResizing
              ? false
              : {
                  top: enableSideResize,
                  right: enableSideResize,
                  bottom: enableSideResize,
                  left: enableSideResize,
                  topLeft: enableCornerResize,
                  topRight: enableCornerResize,
                  bottomLeft: enableCornerResize,
                  bottomRight: enableCornerResize,
                }
          }
          onResize={handleResize}
          className={clsx(pipContainerClasses.resizeAndDragRoot, classes?.resizeAndDragRoot)}
        >
          <PipBody ref={bodyElRef} className={clsx(pipContainerClasses.body, classes?.body)}>
            {children}
          </PipBody>
        </Rnd>
      </Root>
    </Portal>
  );
};

export default forwardRef(PipContainer);
