import { Box, Typography } from '@mui/material';

import IntervalPlayground from './Interval';

const TimerPlayground = () => {
  return (
    <div>
      <Typography variant="h4">Timers</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5">1. useInterval()</Typography>
        <IntervalPlayground />
      </Box>
    </div>
  );
};

export default TimerPlayground;
